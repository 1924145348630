export const ApiConfig = {
   ENDPOINTS: {
      CONFIGS_GET_ACCESS: 'configs.getAccess',

      USERS_LIST:             'users.list',
      USERS_CREATE:           'users.create',
      USERS_CHANGE:           'users.change',
      USERS_REMOVE:           'users.remove',
      USERS_GET:              'users.get',
      USERS_GET_CONFIRM:      'users.getConfirm',
      USERS_SEND_INVITE_MAIL: 'users.sendInviteMail',
      USERS_GET_BY_CONFIRM:   'users.getByConfirm',

      REQUESTS_CREATE: 'requests.create',
      REQUESTS_REJECT: 'requests.reject',

      AUTH_LOGIN:   'auth.login',
      AUTH_CONFIRM: 'auth.confirm',
      AUTH_REFRESH: 'auth.refresh',
      AUTH_LOGOUT:  'auth.logout',

      GROUPS_LIST:           'groups.list',
      GROUPS_CREATE:         'groups.create',
      GROUPS_REMOVE:         'groups.remove',
      GROUPS_GET:            'groups.get',
      GROUPS_TEACHERS_LIST:  'groups.teachersList',
      GROUPS_TEACHERS_TIPS:  'groups.teachersTips',
      GROUPS_CREATE_TEACHER: 'groups.createTeacher',
      GROUPS_REMOVE_TEACHER: 'groups.removeTeacher',
      GROUPS_STUDENTS_LIST:  'groups.studentsList',
      GROUPS_STUDENTS_TIPS:  'groups.studentsTips',
      GROUPS_CREATE_STUDENT: 'groups.createStudent',
      GROUPS_REMOVE_STUDENT: 'groups.removeStudent',

      SUBJECTS_LIST:   'subjects.list',
      SUBJECTS_TIPS:   'subjects.tips',
      SUBJECTS_CREATE: 'subjects.create',
      SUBJECTS_CHANGE: 'subjects.change',
      SUBJECTS_REMOVE: 'subjects.remove',

      JOURNALS_GET_CRUMBS:   'journals.getCrumbs',
      JOURNALS_GET:          'journals.get',
      JOURNALS_EXPOSE_MARK:  'journals.exposeMark',
      JOURNALS_REMOVE_MARK:  'journals.removeMark',
      JOURNALS_EXPOSE_TOTAL: 'journals.exposeTotal',
      JOURNALS_REMOVE_TOTAL: 'journals.removeTotal',

      ARCHIVES_LIST:   'archives.list',
      ARCHIVES_CREATE: 'archives.create',
      ARCHIVES_CHANGE: 'archives.change',
      ARCHIVES_CLOSE:  'archives.close',
      ARCHIVES_REMOVE: 'archives.remove'
   },

   BASE_URL: (process.env.NODE_ENV === 'production'
      ? 'https://api.medrut-miit.ru/'
      : 'http://localhost:5000/'
   ),

   MAX_USERS_PER_PAGE: 20,
   MAX_GROUPS_PER_PAGE: 20,
   MAX_SUBJECTS_PER_PAGE: 20,

   /* < Время в секундах, в течении которого будут совершаться запросы AUTH_REFRESH во время бездействия пользователя (обновляется при любой активности пользователя) > */
   REFRESHS_TIME: (process.env.NODE_ENV === 'production'
      ? 300
      : 600
   ),

   /* < Количество повторений за отрезок времени REFRESHS_TIME запроса AUTH_REFRESH (интервал между запросами: REFRESHS_TIME / REFRESHS_COUNT) > */
   REFRESHS_COUNT: (process.env.NODE_ENV === 'production'
      ? 5
      : 10
   )
}
