export enum Roles {
   STUDENT = 'STUDENT',
   TEACHER = 'TEACHER',
   ADMIN   = 'ADMIN'
}

export enum Extensions {
   CURATOR   = 'CURATOR',
   CHAIRMAN  = 'CHAIRMAN',
   METHODIST = 'METHODIST',
   MANAGER   = 'MANAGER',
   LEADER    = 'LEADER'
}

export enum Permissions {
   SEE_ADMINS = 'SEE_ADMINS',

   PANEL       = 'PANEL',
   PANEL_STATS = 'PANEL_STATS',

   PANEL_REQUESTS        = 'PANEL_REQUESTS',
   PANEL_REQUESTS_ACCEPT = 'PANEL_REQUESTS_ACCEPT',
   PANEL_REQUESTS_REJECT = 'PANEL_REQUESTS_REJECT',

   PANEL_USERS_LIST        = 'PANEL_USERS_LIST',
   PANEL_USERS_PERMISSIONS = 'PANEL_USERS_PERMISSIONS',
   PANEL_USERS_CONFIRM     = 'PANEL_USERS_CONFIRM',

   PANEL_USERS_CREATE         = 'PANEL_USERS_CREATE',
   PANEL_USERS_CREATE_ADMIN   = 'PANEL_USERS_CREATE_ADMIN',
   PANEL_USERS_CREATE_TEACHER = 'PANEL_USERS_CREATE_TEACHER',
   PANEL_USERS_CREATE_STUDENT = 'PANEL_USERS_CREATE_STUDENT',

   PANEL_USERS_CHANGE         = 'PANEL_USERS_CHANGE',
   PANEL_USERS_CHANGE_ADMIN   = 'PANEL_USERS_CHANGE_ADMIN',
   PANEL_USERS_CHANGE_TEACHER = 'PANEL_USERS_CHANGE_TEACHER',
   PANEL_USERS_CHANGE_STUDENT = 'PANEL_USERS_CHANGE_STUDENT',

   PANEL_USERS_REMOVE         = 'PANEL_USERS_REMOVE',
   PANEL_USERS_REMOVE_ADMIN   = 'PANEL_USERS_REMOVE_ADMIN',
   PANEL_USERS_REMOVE_TEACHER = 'PANEL_USERS_REMOVE_TEACHER',
   PANEL_USERS_REMOVE_STUDENT = 'PANEL_USERS_REMOVE_STUDENT',

   PANEL_GROUPS_LIST   = 'PANEL_GROUPS_LIST',
   PANEL_GROUPS_CREATE = 'PANEL_GROUPS_CREATE',
   PANEL_GROUPS_REMOVE = 'PANEL_GROUPS_REMOVE',

   PANEL_GROUPS_TEACHERS_LIST   = 'PANEL_GROUPS_TEACHERS_LIST',
   PANEL_GROUPS_TEACHERS_CREATE = 'PANEL_GROUPS_TEACHERS_CREATE',
   PANEL_GROUPS_TEACHERS_REMOVE = 'PANEL_GROUPS_TEACHERS_REMOVE',

   PANEL_GROUPS_STUDENTS_LIST   = 'PANEL_GROUPS_STUDENTS_LIST',
   PANEL_GROUPS_STUDENTS_CREATE = 'PANEL_GROUPS_STUDENTS_CREATE',
   PANEL_GROUPS_STUDENTS_REMOVE = 'PANEL_GROUPS_STUDENTS_REMOVE',

   PANEL_SUBJECTS_LIST   = 'PANEL_SUBJECTS_LIST',
   PANEL_SUBJECTS_CREATE = 'PANEL_SUBJECTS_CREATE',
   PANEL_SUBJECTS_CHANGE = 'PANEL_SUBJECTS_CHANGE',
   PANEL_SUBJECTS_REMOVE = 'PANEL_SUBJECTS_REMOVE',

   PANEL_LOGS = 'PANEL_LOGS',

   PANEL_ARCHIVES_CREATE  = 'PANEL_ARCHIVES_CREATE',
   PANEL_ARCHIVES_CHANGE  = 'PANEL_ARCHIVES_CHANGE',
   PANEL_ARCHIVES_CLOSE   = 'PANEL_ARCHIVES_CLOSE',
   PANEL_ARCHIVES_REMOVE  = 'PANEL_ARCHIVES_REMOVE',
   PANEL_ARCHIVES_CLOSED  = 'PANEL_ARCHIVES_CLOSED',
   PANEL_ARCHIVES_FUTURE  = 'PANEL_ARCHIVES_FUTURE',
   PANEL_ARCHIVES_EXPIRED = 'PANEL_ARCHIVES_EXPIRED',

   PANEL_PERMISSIONS = 'PANEL_PERMISSIONS',

   JOURNALS              = 'JOURNALS',
   JOURNALS_EXPOSE_MARK  = 'JOURNALS_EXPOSE_MARK',
   JOURNALS_REMOVE_MARK  = 'JOURNALS_REMOVE_MARK',
   JOURNALS_EXPOSE_TOTAL = 'JOURNALS_EXPOSE_TOTAL',
   JOURNALS_REMOVE_TOTAL = 'JOURNALS_REMOVE_TOTAL',
   JOURNALS_VIEW_ALL     = 'JOURNALS_VIEW_ALL',
   JOURNALS_CHANGE_ALL   = 'JOURNALS_CHANGE_ALL',

   TESTS = 'TESTS'
}

interface RoleSchema {
   name: string
   extensions: Extensions[]
   permissions: Permissions[]
}

interface ExtensionSchema {
   name: string
   permissions: Permissions[]
}

export interface PermissionSchema {
   name: string
   description: string
}

export interface AccessSchema {
   roles: Record<Roles, RoleSchema>
   extensions: Record<Extensions, ExtensionSchema>
   permissions: Record<Permissions, PermissionSchema>
}
