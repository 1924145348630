import { AccessActions, AccessState, AccessAction } from './access.types'

const initialState: AccessState = {
   loading: false,
   error: null,
   config: null
}

export function accessReducer (state = initialState, action: AccessAction): AccessState
{
   switch (action.type) {
      case AccessActions.LOADING:
         return {
            loading: true,
            error: null,
            config: null
         }
      case AccessActions.ERROR:
         return {
            loading: false,
            error: action.payload,
            config: null
         }
      case AccessActions.SUCCESS:
         return {
            loading: false,
            error: null,
            config: action.payload
         }
      default:
         return state
   }
}
