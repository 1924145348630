import { getChoosedArchive } from './archives.helpers'
import { ArchivesActions, ArchivesState, ArchivesAction } from './archives.types'

const initialState: ArchivesState = {
   loaded: false,
   loading: false,
   error: null,
   list: [],
   current: null,
   choosed: null
}

export function archivesReducer (state = initialState, action: ArchivesAction): ArchivesState
{
   switch (action.type) {
      case ArchivesActions.LOADING_TOTAL:
         return {
            ...state,
            loading: true,
            error: null,
            list: [],
            current: null,
            choosed: null
         }
      case ArchivesActions.LOADING_PARTIAL:
         return {
            ...state,
            loading: true,
            error: null
         }
      case ArchivesActions.ERROR_TOTAL:
         return {
            loaded: true,
            loading: false,
            error: { ...action.payload, fatal: true },
            list: [],
            current: null,
            choosed: null
         }
      case ArchivesActions.ERROR_PARTIAL:
         return {
            ...state,
            loading: false,
            error: { ...action.payload, fatal: false }
         }
      case ArchivesActions.SUCCESS:
         return {
            ...action.payload,
            loaded: true,
            loading: false,
            error: null,
            choosed: getChoosedArchive(action.payload)
         }
      case ArchivesActions.SELECT:
         return {
            ...state,
            choosed: action.payload
         }
      default:
         return state
   }
}
