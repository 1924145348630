import { Dispatch } from 'redux'
import { RootState } from 'common/store/reducers'
import { TitleActions, TitleAction } from './title.types'

export function TitleSet (title: string)
{
   return (dispatch: Dispatch<TitleAction>, getState: () => RootState) => {
      const state = getState()

      if (!state.auth.loading && state.title !== title) {
         document.title = `${title} | MEDRUT`

         dispatch({
            type: TitleActions.SET,
            payload: title
         })
      }
   }
}
