import { useState, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { classNames } from 'utils/classNames'
import { Loader } from 'components/shared/Loaders/Loader'
import { Button } from 'components/shared/Button'

import { Sidebar } from './Sidebar'
import { Header } from './Header'
import { SidebarLayoutContext } from './SidebarLayoutContext'

import css from './SidebarLayout.module.scss'

export const SidebarLayout = () => {
   const [ open, setOpen ] = useState(false)

   return (
      <SidebarLayoutContext.Provider value={{ open, setOpen }}>
         <div className={classNames(css.page, { [css.open]: open })}>
            <Sidebar />
            <Header />

            <div className={css.darkside} onClick={() => setOpen(false)}>
               <Button
                  className={classNames('focus-light', css.close)}
                  ico="close"
                  theme="icon"
                  tabIndex={open ? 0 : -1}
               />
            </div>

            <div className={css.content}>
               <Suspense fallback={<Loader className={css.loader} />}>
                  <main className={css.main}>
                     <Outlet />
                  </main>
               </Suspense>
            </div>
         </div>
      </SidebarLayoutContext.Provider>
   )
}
