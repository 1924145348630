import { Dispatch } from 'redux'

import { RootState } from 'common/store/reducers'
import { formatApiException } from 'utils/formatApiException'

import { saveChoosedArchive } from './archives.helpers'
import { ArchivesActions, ArchivesAction } from './archives.types'

import {
   archivesService,
   ArchivesCreateBody,
   ArchivesChangeBody,
   ArchivesCloseBody,
   ArchivesRemoveBody
} from 'common/services/archives'

export function ArchivesList ()
{
   return async (dispatch: Dispatch<ArchivesAction>) => {
      try {
         dispatch({ type: ArchivesActions.LOADING_TOTAL })

         const response = await archivesService.list()

         dispatch({
            type: ArchivesActions.SUCCESS,
            payload: response
         })
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: ArchivesActions.ERROR_TOTAL,
            payload: exception
         })
      }
   }
}

export function ArchivesCreate (body: ArchivesCreateBody, callback?: () => void)
{
   return async (dispatch: Dispatch<ArchivesAction>) => {
      try {
         dispatch({ type: ArchivesActions.LOADING_PARTIAL })

         const response = await archivesService.create(body)

         dispatch({
            type: ArchivesActions.SUCCESS,
            payload: response
         })

         callback?.()
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: ArchivesActions.ERROR_PARTIAL,
            payload: exception
         })
      }
   }
}

export function ArchivesChange (body: ArchivesChangeBody, callback?: () => void)
{
   return async (dispatch: Dispatch<ArchivesAction>) => {
      try {
         dispatch({ type: ArchivesActions.LOADING_PARTIAL })

         const response = await archivesService.change(body)

         dispatch({
            type: ArchivesActions.SUCCESS,
            payload: response
         })

         callback?.()
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: ArchivesActions.ERROR_PARTIAL,
            payload: exception
         })
      }
   }
}

export function ArchivesClose (body: ArchivesCloseBody, callback?: () => void)
{
   return async (dispatch: Dispatch<ArchivesAction>) => {
      try {
         dispatch({ type: ArchivesActions.LOADING_PARTIAL })

         const response = await archivesService.close(body)

         dispatch({
            type: ArchivesActions.SUCCESS,
            payload: response
         })

         callback?.()
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: ArchivesActions.ERROR_PARTIAL,
            payload: exception
         })
      }
   }
}

export function ArchivesRemove (body: ArchivesRemoveBody, callback?: () => void)
{
   return async (dispatch: Dispatch<ArchivesAction>) => {
      try {
         dispatch({ type: ArchivesActions.LOADING_PARTIAL })

         const response = await archivesService.remove(body)

         dispatch({
            type: ArchivesActions.SUCCESS,
            payload: response
         })

         callback?.()
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: ArchivesActions.ERROR_PARTIAL,
            payload: exception
         })
      }
   }
}

export function ArchivesSelect (choosed: number)
{
   return (dispatch: Dispatch<ArchivesAction>, getState: () => RootState) => {
      const { archives } = getState()

      if (archives.choosed !== choosed) {
         dispatch({ type: ArchivesActions.SELECT, payload: choosed })
         saveChoosedArchive(choosed)
      }
   }
}
