import { useMemo, useCallback } from 'react'
import { Permissions } from 'common/types/schemas/access.schema'
import { useAccessConfig } from 'hooks/useAccessConfig'
import { useSelector } from 'hooks/useSelector'

interface ArchiveAccessResponse {
   access: boolean
   isNotExist: boolean
   isClosed: boolean
   isFuture: boolean
   isExpires: boolean
}

interface UseCheckAccessResponse {
   isAccessEvery: (...access: Permissions[]) => boolean
   isAccessSome: (...access: Permissions[]) => boolean
   isArchiveAccess: (archiveId?: number | null) => ArchiveAccessResponse
}

export function useCheckAccess (): UseCheckAccessResponse
{
   const { roles, extensions } = useAccessConfig()
   const { archives, auth } = useSelector(state => state)

   const userPermissions = useMemo(() => {
      return (auth.user ? [
         ...roles[auth.user.role].permissions,
         ...(auth.user.extension ? extensions[auth.user.extension].permissions : []),
         ...auth.user.permissions
      ] : [])
   }, [auth.user, roles, extensions])

   const isAccessEvery = useCallback((...access: Permissions[]) => (
      !!access.length && access.every(permission => userPermissions.includes(permission))
   ), [userPermissions])

   const isAccessSome = useCallback((...access: Permissions[]) => (
      access.some(permission => userPermissions.includes(permission))
   ), [userPermissions])

   const isArchiveAccess = useCallback((archiveId: number | null = archives.choosed): ArchiveAccessResponse => {
      const archive = archives.list.find(archive => archive.id === archiveId)

      const isNotExist = !archive
      const isClosed   = !!(archive && archive.closed && !isAccessEvery(Permissions.PANEL_ARCHIVES_CLOSED))
      const isFuture   = !!(archive && new Date(archive.firstStart).getTime() > Date.now() && !isAccessEvery(Permissions.PANEL_ARCHIVES_FUTURE))
      const isExpires  = !!(archive && new Date(archive.secondEnd).getTime() < Date.now() && !isAccessEvery(Permissions.PANEL_ARCHIVES_EXPIRED))

      return {
         access: !(isNotExist || isClosed || isFuture || isExpires),
         isNotExist,
         isClosed,
         isFuture,
         isExpires
      }
   }, [archives.list, archives.choosed, isAccessEvery])

   return {
      isAccessEvery,
      isAccessSome,
      isArchiveAccess
   }
}
