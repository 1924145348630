import dayjs from 'dayjs'
import { ArchiveSchema } from 'common/types/schemas/archive.schema'
import { ucFirstAll } from './ucFirst'

export function getArchiveName (archive: ArchiveSchema, current?: number | null): string
{
   const start = dayjs(archive.firstStart)
   const end = dayjs(archive.secondEnd)

   const format = (start.year() === end.year()
      ? start.month() === end.month()
      ? 'MMMM D, YYYY'
      : 'MMMM YYYY'
      : 'YYYY'
   )

   const name = `${ucFirstAll(start.format(format))} - ${ucFirstAll(end.format(format))}`

   const postfix = (archive.id === current
      ? archive.closed
      ? '(Текущий, Закрыт)'
      : '(Текущий)'
      : null
   )

   return [ name, postfix ].filter(x => x).join(' ')
}
