import { StorageKeys } from 'common/unique'
import { ArchivesResponse } from 'common/services/archives'

export function getChoosedArchive (payload: ArchivesResponse): number | null
{
   const choosed = localStorage.getItem(StorageKeys.ARCHIVES_CHOOSED)
   const parsed = (choosed !== null ? parseInt(choosed, 10) : choosed)

   if (!parsed || !payload.list.some(archive => archive.id === parsed)) {
      localStorage.removeItem(StorageKeys.ARCHIVES_CHOOSED)
      return payload.current || (payload.list[0]?.id ?? null)
   }

   return parsed
}

export function saveChoosedArchive (choosed: number): void
{
   localStorage.setItem(StorageKeys.ARCHIVES_CHOOSED, String(choosed))
}
