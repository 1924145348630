import { StorageKeys } from 'common/unique'
import { MarksThemes } from './themes.types'

export function getMarksTheme (): MarksThemes
{
   const theme = localStorage.getItem(StorageKeys.THEMES_MARKS) as MarksThemes | null

   if (!theme || !Object.values(MarksThemes).includes(theme)) {
      localStorage.removeItem(StorageKeys.THEMES_MARKS)
      return MarksThemes.FOND
   }

   return theme
}

export function saveMarksTheme (theme: MarksThemes): void
{
   localStorage.setItem(StorageKeys.THEMES_MARKS, theme)
}
