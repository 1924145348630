import { classNames } from 'utils/classNames'
import { LoaderProps } from './Loader.types'
import css from './Loader.module.scss'

export const Loader = ({ className }: LoaderProps) => (
   <div className={classNames(css.container, className)}>
      <div className={css.loader}>
         <span />
         <span />
         <span />
         <span />
         <span />
      </div>
   </div>
)
