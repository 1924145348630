import { FormattedApiException } from 'common/types/api.types'
import { UserSchema } from 'common/types/schemas/user.schema'

export interface AuthState {
   loading: boolean
   error: FormattedApiException | null
   user: UserSchema | null
}

export enum AuthActions {
   LOADING_TOTAL = 'AUTH_LOADING_TOTAL',
   LOADING_PARTIAL = 'AUTH_LOADING_PARTIAL',
   ERROR = 'AUTH_ERROR',
   SUCCESS = 'AUTH_SUCCESS'
}

interface LOADING_TOTAL {
   type: AuthActions.LOADING_TOTAL
}

interface LOADING_PARTIAL {
   type: AuthActions.LOADING_PARTIAL
}

interface ERROR {
   type: AuthActions.ERROR
   payload: FormattedApiException | null
}

interface SUCCESS {
   type: AuthActions.SUCCESS
   payload: UserSchema | null
}

export type AuthAction = (
     LOADING_TOTAL
   | LOADING_PARTIAL
   | ERROR
   | SUCCESS
)
