import { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { Permissions } from 'common/types/schemas/access.schema'

import { Layout } from './Layout'
import { Page } from './Page'

const Home = lazy(() => import('components/routes/Home'))
const Panel = lazy(() => import('components/routes/Panel'))

const PanelStats = lazy(() => import('components/routes/Panel/Stats'))

const PanelUsersList = lazy(() => import('components/routes/Panel/Users/UsersList'))
const PanelUsersCreate = lazy(() => import('components/routes/Panel/Users/UsersCreate'))
const PanelUsersChange = lazy(() => import('components/routes/Panel/Users/UsersChange'))
const PanelUsersRemove = lazy(() => import('components/routes/Panel/Users/UsersRemove'))

const PanelSubjects = lazy(() => import('components/routes/Panel/Subjects'))

const PanelGroupsList = lazy(() => import('components/routes/Panel/Groups/GroupsList'))
const PanelGroupsCreate = lazy(() => import('components/routes/Panel/Groups/GroupsCreate'))
const PanelGroupsTeachers = lazy(() => import('components/routes/Panel/Groups/GroupsTeachers'))
const PanelGroupsStudents = lazy(() => import('components/routes/Panel/Groups/GroupsStudents'))
const PanelGroupsRemove = lazy(() => import('components/routes/Panel/Groups/GroupsRemove'))
const PanelGroupsTeacher = lazy(() => import('components/routes/Panel/Groups/GroupsTeacher'))

const PanelRequests = lazy(() => import('components/routes/Panel/Requests'))
const PanelLogs = lazy(() => import('components/routes/Panel/Logs'))

const PanelArchivesList = lazy(() => import('components/routes/Panel/Archives/ArchivesList'))
const PanelArchivesCreate = lazy(() => import('components/routes/Panel/Archives/ArchivesCreate'))
const PanelArchivesChange = lazy(() => import('components/routes/Panel/Archives/ArchivesChange'))
const PanelArchivesClose = lazy(() => import('components/routes/Panel/Archives/ArchivesClose'))
const PanelArchivesRemove = lazy(() => import('components/routes/Panel/Archives/ArchivesRemove'))

const PanelPermissions = lazy(() => import('components/routes/Panel/Permissions'))

const Journals = lazy(() => import('components/routes/Journals'))
const Tests = lazy(() => import('components/routes/Tests'))
const Profile = lazy(() => import('components/routes/Profile'))

const Login = lazy(() => import('components/routes/Login'))
const Register = lazy(() => import('components/routes/Register'))
const Restore = lazy(() => import('components/routes/Restore'))
const Confirm = lazy(() => import('components/routes/Confirm'))

const Help = lazy(() => import('components/routes/Help'))
const Support = lazy(() => import('components/routes/Support'))
const Forbidden = lazy(() => import('components/routes/Troubles/Forbidden'))
const NotFound = lazy(() => import('components/routes/Troubles/NotFound'))

export const Router = () => (
   <Routes>
      <Route path={PathsConfig.HOME()} element={<Layout />}>
         <Route index={true} element={
            <Page
               title="Главная"
               Component={Home}
               access={[]}
            />
         }/>

         <Route path={PathsConfig.PANEL()} element={
            <Page
               title={null}
               Component={Panel}
               access={[ Permissions.PANEL ]}
            />
         }>
            <Route index={true} element={
               <Navigate to={PathsConfig.PANEL_STATS()} replace={true} />
            }/>

            <Route path={PathsConfig.PANEL_STATS()} element={
               <Page
                  title="Статистика"
                  Component={PanelStats}
                  access={[ Permissions.PANEL_STATS ]}
               />
            }/>

            <Route path={PathsConfig.PANEL_REQUESTS()} element={
               <Page
                  title="Заявки"
                  Component={PanelRequests}
                  access={[ Permissions.PANEL_REQUESTS ]}
               />
            }/>

            {[PathsConfig.PANEL_USERS_LIST(), PathsConfig.PANEL_USERS_LIST(':page')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Пользователи"
                     Component={PanelUsersList}
                     access={[ Permissions.PANEL_USERS_LIST ]}
                  />
               }/>
            ))}

            <Route path={PathsConfig.PANEL_USERS_CREATE()} element={
               <Page
                  title="Создать пользователя"
                  Component={PanelUsersCreate}
                  access={[ Permissions.PANEL_USERS_CREATE ]}
               />
            }/>

            {[PathsConfig.PANEL_USERS_CHANGE(), PathsConfig.PANEL_USERS_CHANGE(':userId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Редактировать пользователя"
                     Component={PanelUsersChange}
                     access={[ Permissions.PANEL_USERS_CHANGE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_USERS_REMOVE(), PathsConfig.PANEL_USERS_REMOVE(':userId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Удалить пользователя"
                     Component={PanelUsersRemove}
                     access={[ Permissions.PANEL_USERS_REMOVE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_GROUPS_LIST(), PathsConfig.PANEL_GROUPS_LIST(':page')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Группы"
                     Component={PanelGroupsList}
                     access={[ Permissions.PANEL_GROUPS_LIST ]}
                  />
               }/>
            ))}

            <Route path={PathsConfig.PANEL_GROUPS_CREATE()} element={
               <Page
                  title="Создать группу"
                  Component={PanelGroupsCreate}
                  access={[ Permissions.PANEL_GROUPS_CREATE ]}
               />
            }/>

            {[PathsConfig.PANEL_GROUPS_TEACHERS(), PathsConfig.PANEL_GROUPS_TEACHERS(':groupId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Преподаватели группы"
                     Component={PanelGroupsTeachers}
                     access={[ Permissions.PANEL_GROUPS_TEACHERS_LIST ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_GROUPS_STUDENTS(), PathsConfig.PANEL_GROUPS_STUDENTS(':groupId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Студенты группы"
                     Component={PanelGroupsStudents}
                     access={[ Permissions.PANEL_GROUPS_STUDENTS_LIST ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_GROUPS_REMOVE(), PathsConfig.PANEL_GROUPS_REMOVE(':groupId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Удалить группу"
                     Component={PanelGroupsRemove}
                     access={[ Permissions.PANEL_GROUPS_REMOVE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_GROUPS_TEACHER(), PathsConfig.PANEL_GROUPS_TEACHER(':userId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Группы преподавателя"
                     Component={PanelGroupsTeacher}
                     access={[ Permissions.PANEL_GROUPS_TEACHERS_LIST ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_SUBJECTS(), PathsConfig.PANEL_SUBJECTS(':page')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Предметы"
                     Component={PanelSubjects}
                     access={[ Permissions.PANEL_SUBJECTS_LIST ]}
                  />
               }/>
            ))}

            <Route path={PathsConfig.PANEL_LOGS()} element={
               <Page
                  title="Логи"
                  Component={PanelLogs}
                  access={[ Permissions.PANEL_LOGS ]}
               />
            }/>

            <Route path={PathsConfig.PANEL_ARCHIVES_LIST()} element={
               <Page
                  title="Архивы"
                  Component={PanelArchivesList}
                  access={[]}
               />
            }/>

            <Route path={PathsConfig.PANEL_ARCHIVES_CREATE()} element={
               <Page
                  title="Создать архив"
                  Component={PanelArchivesCreate}
                  access={[ Permissions.PANEL_ARCHIVES_CREATE ]}
               />
            }/>

            {[PathsConfig.PANEL_ARCHIVES_CHANGE(), PathsConfig.PANEL_ARCHIVES_CHANGE(':archiveId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Редактировать архив"
                     Component={PanelArchivesChange}
                     access={[ Permissions.PANEL_ARCHIVES_CHANGE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_ARCHIVES_CLOSE(), PathsConfig.PANEL_ARCHIVES_CLOSE(':archiveId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Закрыть архив"
                     Component={PanelArchivesClose}
                     access={[ Permissions.PANEL_ARCHIVES_CLOSE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_ARCHIVES_REMOVE(), PathsConfig.PANEL_ARCHIVES_REMOVE(':archiveId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Удалить архив"
                     Component={PanelArchivesRemove}
                     access={[ Permissions.PANEL_ARCHIVES_REMOVE ]}
                  />
               }/>
            ))}

            {[PathsConfig.PANEL_PERMISSIONS(), PathsConfig.PANEL_PERMISSIONS(':userId')].map(path => (
               <Route path={path} key={path} element={
                  <Page
                     title="Разрешения"
                     Component={PanelPermissions}
                     access={[ Permissions.PANEL_PERMISSIONS ]}
                  />
               }/>
            ))}
         </Route>

         <Route path={PathsConfig.JOURNALS()} element={
            <Page
               title="Журнал"
               Component={Journals}
               access={[ Permissions.JOURNALS ]}
            />
         }/>

         <Route path={PathsConfig.TESTS()} element={
            <Page
               title="Тестирования"
               Component={Tests}
               access={[ Permissions.TESTS ]}
            />
         }/>

         {[PathsConfig.PROFILE(), PathsConfig.PROFILE(':userId')].map(path => (
            <Route path={path} key={path} element={
               <Page
                  title="Профиль"
                  Component={Profile}
                  access={[]}
               />
            }/>
         ))}

         <Route path={PathsConfig.LOGIN()} element={
            <Page
               title="Войти"
               Component={Login}
            />
         }/>

         <Route path={PathsConfig.REGISTER()} element={
            <Page
               title="Оставить заявку"
               Component={Register}
            />
         }/>

         <Route path={PathsConfig.RESTORE()} element={
            <Page
               title="Восстановление"
               Component={Restore}
            />
         }/>

         <Route path={PathsConfig.CONFIRM(':unique')} element={
            <Page
               title="Подтверждение"
               Component={Confirm}
            />
         }/>

         <Route path={PathsConfig.HELP()} element={
            <Page
               title="Помощь"
               Component={Help}
            />
         }/>

         <Route path={PathsConfig.SUPPORT()} element={
            <Page
               title="Тех-поддержка"
               Component={Support}
            />
         }/>

         <Route path={PathsConfig.FORBIDDEN()} element={
            <Page
               title="403 Forbidden"
               Component={Forbidden}
            />
         }/>

         <Route path={PathsConfig.NOTFOUND()} element={
            <Page
               title="404 NotFound"
               Component={NotFound}
            />
         }/>
      </Route>
   </Routes>
)
