import { PathsConfig } from 'configs/paths.config'
import { Button } from 'components/shared/Button'
import { TroubleProps } from './Trouble.types'
import css from './Trouble.module.scss'

export const Trouble = ({
   status,
   title,
   button = 'home',
   description
}: TroubleProps) => (
   <section className={css.section}>
      <div className={css.container}>
         <div className={css.status}>
            <p>{status}</p>
         </div>

         <div className={css.info}>
            <div className={css.message}>
               <h1>{title}</h1>

               {(Array.isArray(description) ? description : [description]).map(row => (
                  <p key={row}>• {row}</p>
               ))}
            </div>

            <div className={css.buttons}>
               {button === 'home' && (
                  <Button
                     label="На главную"
                     href={PathsConfig.HOME()}
                  />
               )}

               {button === 'refresh' && (
                  <Button
                     label="Перезагрузить"
                     onClick={() => window.location.reload()}
                  />
               )}

               <Button
                  label="Поддержка"
                  theme="primary-lighter"
                  href={PathsConfig.SUPPORT()}
               />
            </div>
         </div>
      </div>
   </section>
)
