import { combineReducers } from 'redux'
import { titleReducer } from './title/title.reducer'
import { accessReducer } from './access/access.reducer'
import { archivesReducer } from './archives/archives.reducer'
import { authReducer } from './auth/auth.reducer'
import { themesReducer } from './themes/themes.reducer'

export const rootReducer = combineReducers({
   title: titleReducer,
   access: accessReducer,
   archives: archivesReducer,
   auth: authReducer,
   themes: themesReducer
})

export type RootState = ReturnType<typeof rootReducer>
