import { FormattedApiException } from 'common/types/api.types'
import { AccessSchema } from 'common/types/schemas/access.schema'

export interface AccessState {
   loading: boolean
   error: FormattedApiException | null
   config: AccessSchema | null
}

export enum AccessActions {
   LOADING = 'ACCESS_LOADING',
   ERROR = 'ACCESS_ERROR',
   SUCCESS = 'ACCESS_SUCCESS'
}

interface LOADING {
   type: AccessActions.LOADING
}

interface ERROR {
   type: AccessActions.ERROR
   payload: FormattedApiException
}

interface SUCCESS {
   type: AccessActions.SUCCESS
   payload: AccessSchema
}

export type AccessAction = (
     LOADING
   | ERROR
   | SUCCESS
)
