export type TitleState = string

export enum TitleActions {
   SET = 'TITLE_SET'
}

interface SET {
   type: TitleActions.SET
   payload: string
}

export type TitleAction = (
   SET
)
