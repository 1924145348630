import { Link } from 'react-router-dom'

import { classNames } from 'utils/classNames'
import { Icon } from 'components/shared/Icon'

import { ButtonProps } from './Button.types'
import css from './Button.module.scss'

export const Button = ({
   className,
   label,
   ico,
   type = 'button',
   theme = 'primary',
   href,
   onClick,
   disabled,
   tabIndex,
}: ButtonProps) => {
   if (href) {
      return (
         <Link
            className={classNames(css.button, css[theme], className, { [css.disabled]: disabled })}
            to={href}
            tabIndex={disabled ? -1 : tabIndex}
         >
            {label}
            {ico && <Icon ico={ico} />}
         </Link>
      )
   }

   return (
      <button
         className={classNames(css.button, css[theme], className, { [css.disabled]: disabled })}
         type={type}
         onClick={onClick}
         disabled={disabled}
         tabIndex={tabIndex}
      >
         {label}
         {ico && <Icon ico={ico} />}
      </button>
   )
}
