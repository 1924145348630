import { useRef, useEffect } from 'react'

type FirstRenderTypes = (
     'default'
   | 'skip'
   | 'instantly'
)

interface DebounceTemp {
   timeout: NodeJS.Timeout | null
   isFirst: boolean
}

export function useDebouncedEffect (first: FirstRenderTypes, callback: () => void, deps: any[], delay: number): void
{
   const temp = useRef<DebounceTemp>({
      timeout: null,
      isFirst: true
   })

   const clear = () => {
      if (temp.current.timeout !== null) {
         clearTimeout(temp.current.timeout)
      }
   }

   useEffect(() => {
      if (temp.current.isFirst) {
         temp.current.isFirst = false
         if (first === 'skip') return
         if (first === 'instantly') return callback()
      }

      temp.current.timeout = setTimeout(() => {
         callback()
         clear()
      }, delay)

      return () => {
         clear()
      }
   }, [delay, ...deps]) // eslint-disable-line
}
