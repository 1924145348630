import { $request } from 'common/request'
import { ApiConfig } from 'configs/api.config'

import {
   ArchivesResponse,
   ArchivesCreateBody,
   ArchivesChangeBody,
   ArchivesCloseBody,
   ArchivesRemoveBody
} from './archives.types'

export const list = async () => {
   const { data } = await $request.post<ArchivesResponse>(ApiConfig.ENDPOINTS.ARCHIVES_LIST)
   return data
}

export const create = async (body: ArchivesCreateBody) => {
   const { data } = await $request.post<ArchivesResponse>(ApiConfig.ENDPOINTS.ARCHIVES_CREATE, body)
   return data
}

export const change = async (body: ArchivesChangeBody) => {
   const { data } = await $request.post<ArchivesResponse>(ApiConfig.ENDPOINTS.ARCHIVES_CHANGE, body)
   return data
}

export const close = async (body: ArchivesCloseBody) => {
   const { data } = await $request.post<ArchivesResponse>(ApiConfig.ENDPOINTS.ARCHIVES_CLOSE, body)
   return data
}

export const remove = async (body: ArchivesRemoveBody) => {
   const { data } = await $request.post<ArchivesResponse>(ApiConfig.ENDPOINTS.ARCHIVES_REMOVE, body)
   return data
}
