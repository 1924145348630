import { useSelector } from 'hooks/useSelector'
import { SidebarLayout } from './SidebarLayout'
import { EmptyLayout } from './EmptyLayout'

export const Layout = () => {
   const { user } = useSelector(state => state.auth)

   return (user
      ? <SidebarLayout />
      : <EmptyLayout />
   )
}
