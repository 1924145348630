export const PathsConfig = {
   HOME: () => '/',
   PANEL: () => '/panel',

   PANEL_STATS: () => '/panel/stats',
   PANEL_REQUESTS: () => '/panel/requests',

   PANEL_USERS_LIST: (page?: string | number) => `/panel/users/list${page ? `/${page}` : ''}`,
   PANEL_USERS_CREATE: () => '/panel/users/create',
   PANEL_USERS_CHANGE: (userId?: string | number) => `/panel/users/change${userId ? `/${userId}` : ''}`,
   PANEL_USERS_REMOVE: (userId?: string | number) => `/panel/users/remove${userId ? `/${userId}` : ''}`,

   PANEL_GROUPS_LIST: (page?: string | number) => `/panel/groups/list${page ? `/${page}` : ''}`,
   PANEL_GROUPS_CREATE: () => '/panel/groups/create',
   PANEL_GROUPS_TEACHERS: (groupId?: string | number) => `/panel/groups/teachers${groupId ? `/${groupId}` : ''}`,
   PANEL_GROUPS_STUDENTS: (groupId?: string | number) => `/panel/groups/students${groupId ? `/${groupId}` : ''}`,
   PANEL_GROUPS_REMOVE: (groupId?: string | number) => `/panel/groups/remove${groupId ? `/${groupId}` : ''}`,
   PANEL_GROUPS_TEACHER: (userId?: string | number) => `/panel/groups/teacher${userId ? `/${userId}` : ''}`,

   PANEL_SUBJECTS: (page?: string | number) => `/panel/subjects${page ? `/${page}` : ''}`,
   PANEL_LOGS: () => '/panel/logs',

   PANEL_ARCHIVES_LIST: () => '/panel/archives/list',
   PANEL_ARCHIVES_CREATE: () => '/panel/archives/create',
   PANEL_ARCHIVES_CHANGE: (archiveId?: string | number) => `/panel/archives/change${archiveId ? `/${archiveId}` : ''}`,
   PANEL_ARCHIVES_CLOSE: (archiveId?: string | number) => `/panel/archives/close${archiveId ? `/${archiveId}` : ''}`,
   PANEL_ARCHIVES_REMOVE: (archiveId?: string | number) => `/panel/archives/remove${archiveId ? `/${archiveId}` : ''}`,

   PANEL_PERMISSIONS: (userId?: string | number) => `/panel/permissions${userId ? `/${userId}` : ''}`,

   JOURNALS: () => '/journals',
   TESTS: () => '/tests',
   PROFILE: (userId?: string | number) => `/profile${userId ? `/${userId}` : ''}`,

   LOGIN: () => '/login',
   REGISTER: () => '/register',
   RESTORE: () => '/restore',
   CONFIRM: (unique: string) => `/confirm/${unique}`,

   HELP: () => '/help',
   SUPPORT: () => '/support',
   FORBIDDEN: () => '/forbidden',
   NOTFOUND: () => '*'
}
