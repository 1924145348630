import { Dispatch } from 'redux'
import { authService, AuthLoginBody, AuthRefreshBody, AuthConfirmBody } from 'common/services/auth'
import { formatApiException } from 'utils/formatApiException'
import { AuthActions, AuthAction } from './auth.types'

export function AuthLogin (body: AuthLoginBody)
{
   return async (dispatch: Dispatch<AuthAction>) => {
      try {
         dispatch({ type: AuthActions.LOADING_TOTAL })

         const user = await authService.login(body)

         dispatch({
            type: AuthActions.SUCCESS,
            payload: user
         })
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: AuthActions.ERROR,
            payload: exception
         })
      }
   }
}

export function AuthConfirm (body: AuthConfirmBody)
{
   return async (dispatch: Dispatch<AuthAction>) => {
      try {
         dispatch({ type: AuthActions.LOADING_TOTAL })

         const user = await authService.confirm(body)

         dispatch({
            type: AuthActions.SUCCESS,
            payload: user
         })
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: AuthActions.ERROR,
            payload: exception
         })
      }
   }
}

export function AuthRefresh (body: AuthRefreshBody)
{
   return async (dispatch: Dispatch<AuthAction>) => {
      try {
         dispatch({ type: AuthActions.LOADING_PARTIAL })

         const user = await authService.refresh(body)

         dispatch({
            type: AuthActions.SUCCESS,
            payload: user
         })
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: AuthActions.ERROR,
            payload: (exception.status !== 401
               ? exception
               : null
            )
         })
      }
   }
}

export function AuthLogout (callback?: () => void)
{
   return async (dispatch: Dispatch<AuthAction>) => {
      try {
         dispatch({ type: AuthActions.LOADING_PARTIAL })

         await authService.logout()

         dispatch({
            type: AuthActions.SUCCESS,
            payload: null
         })

         callback?.()
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: AuthActions.ERROR,
            payload: exception
         })
      }
   }
}
