import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer, Flip } from 'react-toastify'

import dayjs from 'dayjs'
import dayjsRU from 'dayjs/locale/ru'

import 'react-toastify/dist/ReactToastify.css'
import 'styles/toastify.scss'
import 'styles/normalize.scss'
import 'styles/main.scss'
import 'styles/styles.scss'

import { store } from 'common/store'
import { Configs } from 'components/app/Configs'
import { Router } from 'components/app/Router'
import { Refresh } from 'components/app/Refresh'
import { Cookies } from 'components/app/Cookies'
import { Konami } from 'components/app/Konami'

export const App = () => (
   <Provider store={store}>
      <BrowserRouter>
         <Configs>
            <Router />
            <Refresh />
            <Cookies />
            <Konami />
         </Configs>

         <ToastContainer
            theme="light"
            limit={7}
            autoClose={5000}
            transition={Flip}
         />
      </BrowserRouter>
   </Provider>
)

dayjs.locale(dayjsRU.name)
