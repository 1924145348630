import { UserSchema } from 'common/types/schemas/user.schema'
import { ucFirst } from 'utils/ucFirst'

type Profile = UserSchema | null
type Callback = (name: Pick<UserSchema, 'lastname' | 'firstname' | 'middlename'>) => string

const getName = (profile: Profile, callback: Callback) => (profile
   ? callback({
      lastname: ucFirst(profile.lastname),
      firstname: ucFirst(profile.firstname),
      middlename: profile.middlename && ucFirst(profile.middlename)
   })
   : 'Загрузка...'
)

export const getFullName = (profile: Profile): string => getName(profile, ({ lastname, firstname, middlename }) => (
   [ lastname, firstname, middlename ].filter(x => x).join(' ')
))

export const getFullShortName = (profile: Profile): string => getName(profile, ({ lastname, firstname, middlename }) => (
   [ lastname, firstname, (middlename ? `${middlename.charAt(0)}.` : null), ].filter(x => x).join(' ')
))

export const getShortName = (profile: Profile): string => getName(profile, ({ lastname, firstname }) => (
   [ lastname, firstname ].join(' ')
))
