import { Link } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { Permissions } from 'common/types/schemas/access.schema'

import { classNames } from 'utils/classNames'
import { getFullShortName } from 'utils/getUserName'

import { useAccessConfig } from 'hooks/useAccessConfig'
import { useSelector } from 'hooks/useSelector'

import { Icon } from 'components/shared/Icon'
import { Button } from 'components/shared/Button'

import { SidebarPoint } from './SidebarPoint'
import { useSidebarLayoutContext } from '../SidebarLayoutContext'

import css from './Sidebar.module.scss'

export const Sidebar = () => {
   const { roles } = useAccessConfig()
   const { open } = useSidebarLayoutContext()
   const { user } = useSelector(state => state.auth)

   return (
      <div className={classNames(css.sidebar, { [css.open]: open })}>
         <header className={css.header}>
            <Icon
               className={css.logo}
               ico="logo"
            />

            <h3 className={css.title}>
               MEDRUT
            </h3>
         </header>

         <ul className={css.points}>
            <SidebarPoint
               ico="home"
               label="Главная"
               link={PathsConfig.HOME()}
            />

            <SidebarPoint
               ico="server"
               label="Панель"
               link={PathsConfig.PANEL()}
               nesting={true}
               access={[ Permissions.PANEL ]}
            />

            <SidebarPoint
               ico="book"
               label="Журнал"
               link={PathsConfig.JOURNALS()}
               access={[ Permissions.JOURNALS ]}
            />

            <SidebarPoint
               ico="flasks"
               label="Тестирования"
               link={PathsConfig.TESTS()}
               access={[ Permissions.TESTS ]}
            />
         </ul>

         <footer className={css.footer}>
            <div className={css.photo}>
               <Link to={PathsConfig.PROFILE()} tabIndex={open ? -1 : 0}>
                  <img
                     src="/images/empty.jpg"
                     alt="Фотография профиля"
                  />
               </Link>
            </div>

            <div className={css.details}>
               <Button
                  className={classNames('focus-light', css.name)}
                  label={getFullShortName(user)}
                  theme="link"
                  href={PathsConfig.PROFILE()}
                  tabIndex={open ? 0 : -1}
               />

               <p className={css.role}>
                  {user ? roles[user.role].name : 'Загрузка...'}
               </p>
            </div>
         </footer>
      </div>
   )
}
