import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'
import { useDebouncedEffect } from 'hooks/useDebouncedEffect'

import { ApiConfig } from 'configs/api.config'
import { Spinner } from 'components/shared/Loaders/Spinner'

const getCurrentTime = (): number => Math.round(Date.now() / 1000)
const getExpiresTime = (): number => getCurrentTime() + ApiConfig.REFRESHS_TIME

export const Refresh = () => {
   const { archives, auth } = useSelector(state => state)
   const { AuthRefresh } = useActions()
   const [ expires, setExpires ] = useState(getExpiresTime() + 1)

   useEffect(() => {
      if (auth.user) {
         const updateActivity = () => {
            if (getCurrentTime() > expires) {
               setExpires(getExpiresTime)
            }
         }

         document.addEventListener('mousedown', updateActivity)
         document.addEventListener('keydown', updateActivity)

         const interval = setInterval(() => {
            if (getCurrentTime() <= expires) {
               AuthRefresh({ archiveId: archives.choosed })
            }
         }, ApiConfig.REFRESHS_TIME / ApiConfig.REFRESHS_COUNT * 1000)

         return () => {
            document.removeEventListener('mousedown', updateActivity)
            document.removeEventListener('keydown', updateActivity)
            clearInterval(interval)
         }
      }
   }, [auth.user, expires, archives.choosed]) // eslint-disable-line

   useDebouncedEffect('instantly', () => {
      AuthRefresh({ archiveId: archives.choosed })
   }, [archives.choosed], 800)

   useEffect(() => {
      if (auth.error) toast.error(auth.error.message)
   }, [auth.error])

   return (auth.loading
      ? <Spinner mode="fixed" position="bottom-right" />
      : null
   )
}
