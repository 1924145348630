export enum StorageKeys {
   COOKIES_WARNING = 'COOKIES_WARNING', // Предупреждение об использовании cookie файлов

   USER_CHANGE_WARNING = 'USER_CHANGE_WARNING', // Предупреждение про изменение пользователя
   USER_REMOVE_WARNING = 'USER_REMOVE_WARNING', // Предупреждение про удаление пользователя

   SUBJECT_CHANGE_WARNING = 'SUBJECT_CHANGE_WARNING', // Предупреждение про изменение предмета
   SUBJECT_REMOVE_WARNING = 'SUBJECT_REMOVE_WARNING', // Предупреждение про удаление предмета

   GROUP_TEACHERS_WARNING = 'GROUP_TEACHERS_WARNING', // Предупреждение про удаление преподавателя
   GROUP_STUDENTS_WARNING = 'GROUP_STUDENTS_WARNING', // Предупреждение про удаление студента
   GROUP_REMOVE_WARNING   = 'GROUP_REMOVE_WARNING',   // Предупреждение про удаление группы

   ARCHIVE_CREATE_WARNING = 'ARCHIVE_CREATE_WARNING', // Предупреждение про создание архива
   ARCHIVE_CHANGE_WARNING = 'ARCHIVE_CHANGE_WARNING', // Предупреждение про изменение архива
   ARCHIVE_CLOSE_WARNING  = 'ARCHIVE_CLOSE_WARNING',  // Предупреждение про закрытие архива
   ARCHIVE_REMOVE_WARNING = 'ARCHIVE_REMOVE_WARNING', // Предупреждение про удаление архива

   USERS_FILTERS_SEARCH     = 'USERS_FILTERS_SEARCH',     // Поисковая строка в фильтрах пользователей
   USERS_FILTERS_SORT       = 'USERS_FILTERS_SORT',       // Сортировка в фильтрах пользователей
   USERS_FILTERS_ROLES      = 'USERS_FILTERS_ROLES',      // Роли в фильтрах пользователей
   USERS_FILTERS_EXTENSIONS = 'USERS_FILTERS_EXTENSIONS', // Расширения в фильтрах пользователей
   USERS_FILTERS_ACTIVITY   = 'USERS_FILTERS_ACTIVITY',   // Активности в фильтрах пользователей
   USERS_FILTERS_DETAILS    = 'USERS_FILTERS_DETAILS',    // Детали в фильтрах пользователей
   USERS_FILTERS_PAGE       = 'USERS_FILTERS_PAGE',       // Номер страницы в фильтрах пользователей

   GROUPS_FILTERS_BRANCHES = 'GROUPS_FILTERS_BRANCHES', // Ветвь в фильтрах групп
   GROUPS_FILTERS_COURSES  = 'GROUPS_FILTERS_COURSES',  // Курс в фильтрах групп
   GROUPS_FILTERS_NUMBERS  = 'GROUPS_FILTERS_NUMBERS',  // Номер в фильтрах групп
   GROUPS_FILTERS_PAGE     = 'GROUPS_FILTERS_PAGE',     // Номер страницы в фильтрах групп

   JOURNAL_GROUP_ID    = 'JOURNAL_GROUP_ID',   // Группа в журнале
   JOURNAL_SUBJECT_ID  = 'JOURNAL_SUBJECT_ID', // Предмет в журнале
   JOURNAL_TYPE        = 'JOURNAL_TYPE',       // Тип журнала

   THEMES_MARKS     = 'THEMES_MARKS',    // Тема оценок
   ARCHIVES_CHOOSED = 'ARCHIVES_CHOOSED' // Выбранный архив
}

export enum ToastKeys {
   JOURNAL_MARK_ACCESS_ERROR  = 'journal_mark_access_error', // Ключ для уведомления о ошибке доступа для ячейки оценки
   JOURNAL_TOTAL_ACCESS_ERROR = 'journal_total_access_error' // Ключ для уведомления о ошибке доступа для ячейки итоговой оценки
}
