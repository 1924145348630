import { Link, useMatch } from 'react-router-dom'

import { classNames } from 'utils/classNames'
import { useCheckAccess } from 'hooks/useCheckAccess'

import { Icon } from 'components/shared/Icon'
import { Button } from 'components/shared/Button'
import { SidebarPointProps } from './Sidebar.types'

import css from './Sidebar.module.scss'

export const SidebarPoint = ({ ico, label, link, nesting, access = [] }: SidebarPointProps) => {
   const { isAccessEvery } = useCheckAccess()
   const active = !!useMatch(nesting ? `${link}/*` : link)

   if (access.length && !isAccessEvery(...access)) {
      return null
   }

   return (
      <li className={classNames(css.point, { [css.active]: active })}>
         <Link className={classNames('focus-light', css.container)} to={link}>
            <Icon className={css.icon} ico={ico} />
            <span className={css.label}>
               {label}
            </span>
         </Link>

         <div className={css.tooltip}>
            <Button
               label={label}
               theme="link"
               href={link}
               tabIndex={-1}
            />
         </div>
      </li>
   )
}
