import { AuthActions, AuthState, AuthAction } from './auth.types'

const initialState: AuthState = {
   loading: false,
   error: null,
   user: null
}

export function authReducer (state = initialState, action: AuthAction): AuthState
{
   switch (action.type) {
      case AuthActions.LOADING_TOTAL:
         return {
            loading: true,
            error: null,
            user: null
         }
      case AuthActions.LOADING_PARTIAL:
         return {
            ...state,
            loading: true,
            error: null
         }
      case AuthActions.ERROR:
         return {
            loading: false,
            error: action.payload,
            user: null
         }
      case AuthActions.SUCCESS:
         return {
            loading: false,
            error: null,
            user: action.payload
         }
      default:
         return state
   }
}
