import { Dispatch } from 'redux'
import { configsService } from 'common/services/configs'
import { formatApiException } from 'utils/formatApiException'
import { AccessActions, AccessAction } from './access.types'

export function AccessGet ()
{
   return async (dispatch: Dispatch<AccessAction>) => {
      try {
         dispatch({ type: AccessActions.LOADING })

         const config = await configsService.getAccess()

         dispatch({
            type: AccessActions.SUCCESS,
            payload: config
         })
      }
      catch (err) {
         const exception = formatApiException(err)

         dispatch({
            type: AccessActions.ERROR,
            payload: exception
         })
      }
   }
}
