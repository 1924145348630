import { useState, useEffect } from 'react'
import { StorageKeys } from 'common/unique'

import { classNames } from 'utils/classNames'
import { getArchiveName } from 'utils/getArchiveName'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'
import { useFocusOut } from 'hooks/useFocusOut'
import { useMountEffect } from 'hooks/useMountEffect'

import { Button } from 'components/shared/Button'
import css from './Archives.module.scss'

export const Archives = () => {
   const { loading, list, current, choosed } = useSelector(state => state.archives)

   const { ArchivesSelect    } = useActions()
   const [ toggle, setToggle ] = useState(false)

   const container = useFocusOut<HTMLDivElement>(toggle, () => {
      setToggle(false)
   })

   useEffect(() => {
      setToggle(false)
   }, [choosed])

   useMountEffect(() => {
      localStorage.removeItem(StorageKeys.USERS_FILTERS_SEARCH)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_SORT)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_ROLES)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_EXTENSIONS)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_ACTIVITY)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_DETAILS)
      localStorage.removeItem(StorageKeys.USERS_FILTERS_PAGE)
      localStorage.removeItem(StorageKeys.GROUPS_FILTERS_BRANCHES)
      localStorage.removeItem(StorageKeys.GROUPS_FILTERS_COURSES)
      localStorage.removeItem(StorageKeys.GROUPS_FILTERS_NUMBERS)
      localStorage.removeItem(StorageKeys.GROUPS_FILTERS_PAGE)
      localStorage.removeItem(StorageKeys.JOURNAL_GROUP_ID)
      localStorage.removeItem(StorageKeys.JOURNAL_SUBJECT_ID)
      localStorage.removeItem(StorageKeys.JOURNAL_TYPE)
   }, [choosed])

   return (
      <div
         className={classNames(css.archives, {
            [css.open]: toggle,
            [css.warning]: (current === null || current !== choosed || list.find(archive => archive.id === current)?.closed)
         })}
         ref={container}
      >
         <Button
            className={css.toggle}
            ico="archive"
            theme="icon"
            onClick={() => setToggle(state => !state)}
            disabled={loading}
         />

         <div className={classNames('scroll-y', 'block', css.container)}>
            {list.map(archive => (
               <button
                  className={classNames('focus-dark', css.archive, { [css.choosed]: archive.id === choosed })}
                  type="button"
                  onClick={() => ArchivesSelect(archive.id)}
                  disabled={!toggle}
                  key={archive.id}
               >
                  {getArchiveName(archive, current)}
               </button>
            ))}

            {!list.length && (
                <p className="empty">
                  Архивы не найдены
               </p>
            )}
         </div>
      </div>
   )
}
