import { StorageKeys } from 'common/unique'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { Flow } from 'components/shared/Animations/Flow'
import { Alert } from 'components/shared/Alert'
import css from './Cookies.module.scss'

export const Cookies = () => {
   const [ accept, setAccept ] = useLocalStorage(StorageKeys.COOKIES_WARNING, false)

   return (
      <Flow type="fade" active={!accept}>
         <Alert
            className={css.banner}
            ico="cookie"
            label="Для улучшения работы сайта и его взаимодействия с пользователями мы используем файлы cookie. Продолжая работу с сайтом, Вы разрешаете использование cookie-файлов."
            button="Хорошо"
            onClick={() => setAccept(true)}
         />
      </Flow>
   )
}
