import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { PathsConfig } from 'configs/paths.config'
import { LoginState, ForbiddenState } from 'common/types/locations.types'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'
import { useCheckAccess } from 'hooks/useCheckAccess'

import { PageProps } from './Router.types'

export const Page = ({ title, access, Component }: PageProps) => {
   const { pathname, search } = useLocation()
   const { user } = useSelector(state => state.auth)
   const { TitleSet } = useActions()
   const { isAccessEvery } = useCheckAccess()
   const hasAccess = (access?.length ? isAccessEvery(...access) : true)

   useEffect(() => {
      if (title && hasAccess) TitleSet(title)
   }, [title, hasAccess]) // eslint-disable-line

   if (access) {
      if (!user) {
         const state: LoginState = {
            from: `${pathname}${search}`
         }

         return (
            <Navigate
               to={PathsConfig.LOGIN()}
               state={state}
               replace={true}
            />
         )
      }

      if (!hasAccess) {
         const state: ForbiddenState = {
            from: `${pathname}${search}`
         }

         return (
            <Navigate
               to={PathsConfig.FORBIDDEN()}
               state={state}
               replace={true}
            />
         )
      }
   }

   return <Component />
}
