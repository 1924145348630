import * as titleActions from './title/title.actions'
import * as accessActions from './access/access.actions'
import * as archivesActions from './archives/archives.actions'
import * as authActions from './auth/auth.actions'
import * as themesActions from './themes/themes.actions'

export const ActionCreators = {
   ...titleActions,
   ...accessActions,
   ...archivesActions,
   ...authActions,
   ...themesActions
}
