import { $request } from 'common/request'
import { ApiConfig } from 'configs/api.config'
import { UserSchema } from 'common/types/schemas/user.schema'
import { AuthLoginBody, AuthRefreshBody, AuthConfirmBody } from './auth.types'

export const login = async (body: AuthLoginBody) => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_LOGIN, body)
   return data
}

export const confirm = async (body: AuthConfirmBody) => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_CONFIRM, body)
   return data
}

export const refresh = async (body: AuthRefreshBody) => {
   const { data } = await $request.post<UserSchema>(ApiConfig.ENDPOINTS.AUTH_REFRESH, body)
   return data
}

export const logout = async () => {
   const { data } = await $request.post<true>(ApiConfig.ENDPOINTS.AUTH_LOGOUT)
   return data
}
