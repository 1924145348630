import { TitleActions, TitleState, TitleAction } from './title.types'

const initialState: TitleState = 'Загрузка'

export function titleReducer (state = initialState, action: TitleAction): TitleState
{
   switch (action.type) {
      case TitleActions.SET:
         return action.payload
      default:
         return state
   }
}
