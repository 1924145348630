import axios from 'axios'
import { ApiConfig } from 'configs/api.config'
import { PathsConfig } from 'configs/paths.config'

const $request = axios.create({
   baseURL: ApiConfig.BASE_URL,
   withCredentials: true
})

$request.interceptors.response.use((response) => response, async (error) => {
   const status = error.response?.status
   const config = error.config

   if (status !== 401 || !config || config.url === ApiConfig.ENDPOINTS.AUTH_REFRESH) {
      return Promise.reject(error)
   }

   try {
      await $request.post(ApiConfig.ENDPOINTS.AUTH_REFRESH)
      return $request.request(config)
   }
   catch (err) {
      window.location.replace(PathsConfig.LOGIN())
      return Promise.reject(error)
   }
})

export { $request }
