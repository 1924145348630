import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { ComponentProps } from 'common/types/components.types'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'

import { Spinner } from 'components/shared/Loaders/Spinner'
import { Trouble } from 'components/shared/Trouble'

import css from './Configs.module.scss'

export const Configs = ({ children }: ComponentProps) => {
   const { access, archives } = useSelector(state => state)
   const { AccessGet, ArchivesList } = useActions()

   const error = access.error || archives.error
   const fatal = access.error || (archives.error && archives.error.fatal)

   useEffect(() => {
      AccessGet()
      ArchivesList()
   }, []) // eslint-disable-line

   useEffect(() => {
      if (error) toast.error(error.message)
   }, [error])

   if (access.loading || !archives.loaded) {
      return (
         <div className={css.page}>
            <Spinner />
         </div>
      )
   }

   if (error && fatal) {
      return (
         <div className={css.page}>
            <Trouble
               status={error.status ?? 500}
               title="Неизвестная ошибка"
               button="refresh"
               description={[
                  'Важные для работы приложения данные не были загружены.',
                  error.message,
                  'Повторите попытку позже или обратитесь в тех-поддержку.'
               ]}
            />
         </div>
      )
   }

   return <>
      {access.config && children}
   </>
}
