import { Children } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { FlowTypes, FlowProps, FlowGroupProps } from './Flow.types'
import './Flow.scss'

const timeouts: Record<FlowTypes, number> = {
   'fade': 400,
   'fade-top': 400,
   'fade-left': 400,
   'fade-bottom': 400,
   'fade-right': 400
}

export const Flow = ({ type, active, children }: FlowProps) => (
   <CSSTransition
      in={active}
      classNames={type}
      timeout={timeouts[type]}
      mountOnEnter={true}
      unmountOnExit={true}
   >
      {children}
   </CSSTransition>
)

export const FlowGroup = ({ className, type, children }: FlowGroupProps) => (
   <TransitionGroup className={className}>
      {Children.map(children, child => (
         <CSSTransition classNames={type} timeout={timeouts[type]}>
            {child}
         </CSSTransition>
      ))}
   </TransitionGroup>
)
