import { classNames } from 'utils/classNames'
import { Icon } from 'components/shared/Icon'
import { SpinnerProps } from './Spinner.types'
import css from './Spinner.module.scss'

export const Spinner = ({
   className,
   mode = 'static',
   position = 'none'
}: SpinnerProps) => (
   <div className={classNames(css.spinner, css[mode], css[position], className)}>
      <Icon ico="spinner" />
   </div>
)
