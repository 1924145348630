export enum MarksThemes {
   NONE = 'NONE',
   TEXT = 'TEXT',
   FOND = 'FOND'
}

export interface ThemesState {
   marksTheme: MarksThemes
}

export enum ThemesActions {
   SET_MARKS = 'THEMES_SET_MARKS'
}

interface SET_MARKS {
   type: ThemesActions.SET_MARKS
   payload: MarksThemes
}

export type ThemesAction = (
   SET_MARKS
)
