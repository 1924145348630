import axios, { AxiosResponse } from 'axios'
import { ApiException, FormattedApiException } from 'common/types/api.types'

function getApiException (response: AxiosResponse<ApiException>): ApiException | null
{
   const { status, message, details } = response.data || {}

   return (status && message && details
      ? { status, message, details }
      : null
   )
}

export function formatApiException (err: unknown): FormattedApiException
{
   if (!axios.isAxiosError(err)) {
      return { message: 'Произошла неизвестная ошибка при запросе' }
   }

   if (err.response) {
      const exception = getApiException(err.response)

      if (!exception) {
         return { message: 'Неизвестная серверная ошибка' }
      }

      return exception
   }

   if (err.request) {
      return { message: 'Запрос был отправлен, но ответ не был получен. Проверьте ваше подключение к интернету' }
   }

   return { message: 'Произошла неизвестная ошибка сервиса при запросе' }
}
