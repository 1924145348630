import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PathsConfig } from 'configs/paths.config'
import { classNames } from 'utils/classNames'

import { useSelector } from 'hooks/useSelector'
import { useActions } from 'hooks/useActions'

import { Button } from 'components/shared/Button'
import { Archives } from './Archives'

import { useSidebarLayoutContext } from '../SidebarLayoutContext'
import css from './Header.module.scss'

export const Header = () => {
   const navigate = useNavigate()
   const { open, setOpen } = useSidebarLayoutContext()
   const { title, auth } = useSelector(state => state)
   const { AuthLogout } = useActions()

   const logout = useCallback(() => {
      AuthLogout(() => {
         toast.success('Вы успешно вышли из аккаунта')
         navigate(PathsConfig.LOGIN(), { replace: true })
      })
   }, [navigate]) // eslint-disable-line

   return (
      <header className={classNames(css.header, { [css.open]: open })}>
         <div className={css.crumbs}>
            <Button
               className={css.menu}
               ico="menu"
               theme="icon"
               onClick={() => setOpen(state => !state)}
            />

            <h1 className={css.title}>
               {title}
            </h1>
         </div>

         <div className={css.services}>
            <Archives />

            <Button
               className={css.logout}
               ico="exit"
               theme="icon"
               onClick={logout}
               disabled={auth.loading}
            />
         </div>
      </header>
   )
}
