import { getMarksTheme } from './themes.helpers'
import { ThemesActions, ThemesState, ThemesAction } from './themes.types'

const initialState: ThemesState = {
   marksTheme: getMarksTheme()
}

export function themesReducer (state = initialState, action: ThemesAction): ThemesState
{
   switch (action.type) {
      case ThemesActions.SET_MARKS:
         return { ...state, marksTheme: action.payload }
      default:
         return state
   }
}
