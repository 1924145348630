import { FormattedApiException } from 'common/types/api.types'
import { ArchivesResponse } from 'common/services/archives'

export interface ArchivesState extends ArchivesResponse {
   loaded: boolean
   loading: boolean
   error: (FormattedApiException & { fatal: boolean }) | null
   choosed: number | null
}

export enum ArchivesActions {
   LOADING_TOTAL = 'ARCHIVES_LOADING_TOTAL',
   LOADING_PARTIAL = 'ARCHIVES_LOADING_PARTIAL',
   ERROR_TOTAL = 'ARCHIVES_ERROR_TOTAL',
   ERROR_PARTIAL = 'ARCHIVES_ERROR_PARTIAL',
   SUCCESS = 'ARCHIVES_SUCCESS',
   SELECT = 'ARCHIVES_SELECT'
}

interface LOADING_TOTAL {
   type: ArchivesActions.LOADING_TOTAL
}

interface LOADING_PARTIAL {
   type: ArchivesActions.LOADING_PARTIAL
}

interface ERROR_TOTAL {
   type: ArchivesActions.ERROR_TOTAL
   payload: FormattedApiException
}

interface ERROR_PARTIAL {
   type: ArchivesActions.ERROR_PARTIAL
   payload: FormattedApiException
}

interface SUCCESS {
   type: ArchivesActions.SUCCESS
   payload: ArchivesResponse
}

interface SELECT {
   type: ArchivesActions.SELECT
   payload: number
}

export type ArchivesAction = (
     LOADING_TOTAL
   | LOADING_PARTIAL
   | ERROR_TOTAL
   | ERROR_PARTIAL
   | SUCCESS
   | SELECT
)
