import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Loader } from 'components/shared/Loaders/Loader'
import css from './EmptyLayout.module.scss'

export const EmptyLayout = () => (
   <div className={css.page}>
      <Suspense fallback={<Loader />}>
         <Outlet />
      </Suspense>
   </div>
)
