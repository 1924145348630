import { useRef, useEffect } from 'react'

export function useMountEffect (callback: () => void, deps: any[]): void
{
   const mount = useRef<boolean>(false)

   useEffect(() => {
      if (mount.current) {
         callback()
      } else {
         mount.current = true
      }
   }, [...deps]) // eslint-disable-line
}
