import { Dispatch } from 'redux'
import { saveMarksTheme } from './themes.helpers'
import { ThemesActions, ThemesAction, MarksThemes } from './themes.types'

export function ThemesSetMarks (theme: MarksThemes)
{
   return async (dispatch: Dispatch<ThemesAction>) => {
      dispatch({ type: ThemesActions.SET_MARKS, payload: theme })
      saveMarksTheme(theme)
   }
}
